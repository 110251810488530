import React from 'react'
import { Link, Redirect, NavLink } from 'react-router-i18n'
import PropTypes from 'prop-types'
import 'url-search-params-polyfill';
import {
    isSafari,
    isMacOs, isIE, isEdge, isIOS, isFirefox
} from "react-device-detect";
import I18n from '../i18n';
function WebpIsSupported() {
    let elem = document.createElement('canvas');

    if (!!(elem.getContext && elem.getContext('2d'))) {
        // was able or not to get WebP representation
        return (elem.toDataURL('image/webp').indexOf('data:image/webp') === 0);
    }else{
        return false;
    }
}

const stripLocale = () => {
    let pathname = window.location.pathname;
    if(pathname.indexOf('/en/') !== -1){
        pathname = pathname.slice(3);
    }else{
        pathname = '/en' + pathname;
    }

    return pathname;
};

const localeTitle = () => {
    let title = 'en';
    let pathname = window.location.pathname;
    if(pathname.indexOf('/en/') !== -1){
        title = 'ru';
    }else{
        title = 'en';
    }
    return title;
};

export default class Header extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }


    constructor(props){
        super(props);
        this.state = {
            mode: 'parent',
            webp: false,
            nav: false
        }
    }

    componentDidMount() {
        if((isSafari === true && isMacOs === true) || isIE || isIOS || isEdge || isFirefox){
            this.setState({
                webp: false
            }, ()=>{
                document.body.classList.add('no-webp-mode');
            });
        }else{
            this.setState({
                webp: WebpIsSupported()
            }, ()=>{
                if(this.state.webp === true){
                    document.body.classList.add('webp-mode');
                }
            });
        }
    }


    closeNav = () => {
        this.setState({
            nav: false
        });
        document.body.classList.remove('overflow');
    }

    renderUserBtn = () => {
        if(localStorage.getItem('auth_key')){
            return(
                <div className="hd__item hd__item--indent hidden-sm-down d-flex align-items-center">
                    <Link to="/cabinet/profile" className="d-flex align-items-center justify-content-center">
                        <i className="icon__custom icon__user"></i>
                    </Link>
                    <div className="form__after">
                        <a onClick={()=>{
                            localStorage.removeItem('auth_key');
                            localStorage.removeItem('name');
                            localStorage.removeItem('email');
                            localStorage.removeItem('phone');
                            window.location.reload();
                        }}><I18n t="config.logout_lbl"/></a>
                    </div>
                </div>
            )
        }else{
            return(
                <div className="hd__item hd__item--indent hidden-sm-down">
                    <a onClick={()=>{
                        this.props.showLoginModal();
                        let _tmr = window._tmr || (window._tmr = []);
                        _tmr.push({"type":"reachGoal","id":3245002,"goal":"clickRegEnter"});
                    }} className="d-flex align-items-center justify-content-center">
                        <i className="icon__custom icon__user"></i>
                    </a>
                </div>
            )
        }
    }

    render() {

        return (
            <>
                <link
                    rel="preload"
                    as="image"
                    href={require("../assets/img/logo.svg")}
                />

                <div id="header" className="header">
                    <div className="finish__badge d-flex align-items-end justify-content-center">
                        <div className="finish__badge-title">
                            <I18n t="nav.finish"/>
                        </div>
                    </div>
                    <div className="container d-flex justify-content-between align-items-start">
                        <div className="slogan__box">
                            <div className="slogan">
                                THE CENTRE IN ALL RESPECTS
                            </div>
                        </div>
                        <div className="logo__box">
                            <Link to="/" className="logo">
                                <img src={require("../assets/img/logo.svg")} alt="Клубный дом Turgenev"/>
                            </Link>
                        </div>
                        <div className="header__right d-flex align-items-start">
                            <div className="header__phone-box d-flex align-items-center hidden-sm-down">
                                <div className="header__phone">
                                    <I18n t="config.phone_lbl"/>: <a onClick={()=>{
                                    let _tmr = window._tmr || (window._tmr = []);
                                    _tmr.push({"type":"reachGoal","id":3245002,"goal":"clickNumber"});
                                }} href="tel:+74952609227">(495) 260-92-27</a>
                                </div>
                            </div>
                            <div className="hd__item hidden-sm-down">
                                <a onClick={()=>{
                                    let _tmr = window._tmr || (window._tmr = []);
                                    _tmr.push({"type":"reachGoal","id":3245002,"goal":"clickPhone"});
                                }} href="tel:+74952609227" className="d-flex align-items-center justify-content-center">
                                    <i className="icon__custom icon__phone"></i>
                                </a>
                            </div>
                            {this.renderUserBtn()}
                            <div className="hd__item hd__item--lang">
                                <NavLink onClick={()=>{
                                    window.location.href = stripLocale();

                                }} ignoreLocale to={stripLocale()} className="d-flex align-items-center justify-content-center">
                                    <i className={'icon__custom icon__' + localeTitle()}></i>
                                </NavLink>
                            </div>
                            <div className="hd__item hd__item-bars">
                                <a onClick={()=>{
                                    this.setState({
                                        nav: true
                                    });
                                    document.body.classList.add('overflow');
                                    let _tmr = window._tmr || (window._tmr = []);
                                    _tmr.push({"type":"reachGoal","id":3245002,"goal":"clickMenu"});
                                }} className="d-flex justify-content-center">
                                    <i className="icon__custom icon__bars"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'nav__box ' + ((this.state.nav) ? 'active' : '')}>
                    <div className="nav__box-bg-left"></div>
                    <div className="nav__box-bg-right"></div>
                    <div className="finish__badge d-flex align-items-end justify-content-center">
                        <div className="finish__badge-title">
                            <I18n t="nav.finish"/>
                        </div>
                    </div>
                    <div onClick={()=>{
                        this.setState({
                            nav: false
                        });
                        document.body.classList.remove('overflow');
                    }} className="nav__close">
                        <svg viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M60 36.4898L36.0002 60M36 36L59.9998 59.5102" stroke="#B4B4B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div className="container d-flex align-items-center">
                        <div className="nav__box-ct">
                            <Link onClick={()=>{
                                this.closeNav()
                            }} to="/" className="nav__logo hidden-sm-up">
                                <img src={require("../assets/img/logo.svg")}/>
                            </Link>
                            <div className="row row__custom align-items-center">
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 col__custom">
                                    <div className="nav__box-left">
                                        <div className="nav__logo hidden-sm-down">
                                            <img src={require("../assets/img/nav-logo.svg")}/>
                                        </div>
                                        <div className="nav__data">
                                            <div className="nav__data-box">
                                                <div className="nav__data-box-label">
                                                    <I18n t="config.address_lbl"/>
                                                </div>
                                                <div className="nav__data-box-value">
                                                    <I18n t="config.ct1"/>
                                                </div>
                                            </div>
                                            <div className="nav__data-box">
                                                <div className="nav__data-box-label">
                                                    <I18n t="config.ct2"/>
                                                </div>
                                                <div className="nav__data-box-value" dangerouslySetInnerHTML={{__html: I18n.getTranslation(this.props.location, 'config.ct3')}}/>
                                            </div>
                                            <div className="nav__data-box">
                                                <div className="nav__data-box-label">
                                                    <I18n t="config.ct4"/>
                                                </div>
                                                <div className="nav__data-box-value">
                                                    <I18n t="config.ct5"/>
                                                </div>
                                            </div>
                                            <div className="nav__data-box">
                                                <div className="nav__data-box-value">
                                                    <a onClick={()=>{
                                                        let _tmr = window._tmr || (window._tmr = []);
                                                        _tmr.push({"type":"reachGoal","id":3245002,"goal":"clickNumber"});
                                                    }} href="tel:+74952609227">(495) 260-92-27</a>
                                                </div>
                                            </div>
                                            <div className="nav__data-box">
                                                <div className="nav__data-box-value">
                                                    <a onClick={()=>{
                                                        let _tmr = window._tmr || (window._tmr = []);
                                                        _tmr.push({"type":"reachGoal","id":3245002,"goal":"clickMail"});
                                                    }} href="mailto:sale@turgenevdom.ru">sale@turgenevdom.ru</a>
                                                </div>
                                            </div>

                                            <div className="hd__item hd__item--lang hidden-sm-up">
                                                <NavLink onClick={()=>{
                                                    window.location.href = stripLocale();

                                                }} ignoreLocale to={stripLocale()} className="d-flex align-items-center justify-content-center">
                                                    <i className={'icon__custom icon__' + localeTitle()}></i>
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className="nav__social d-flex">
                                            <a onClick={()=>{
                                                let _tmr = window._tmr || (window._tmr = []);
                                                _tmr.push({"type":"reachGoal","id":3245002,"goal":"clickSocial"});
                                            }} href="https://vk.com/turgenevdom" target="_blank">
                                                <i className="icon__vk-gr"></i>
                                            </a>
                                            <a onClick={()=>{
                                                let _tmr = window._tmr || (window._tmr = []);
                                                _tmr.push({"type":"reachGoal","id":3245002,"goal":"clickSocial"});
                                            }} href="mailto:sale@turgenevdom.ru" target="_blank">
                                                <i className="icon__em-gr"></i>
                                            </a>
                                            <a onClick={()=>{
                                                let _tmr = window._tmr || (window._tmr = []);
                                                _tmr.push({"type":"reachGoal","id":3245002,"goal":"clickSocial"});
                                            }} href="https://zen.yandex.ru/id/61e82011bc2bdd62bf29b043" target="_blank">
                                                <i className="icon__zen-gr"></i>
                                            </a>
                                            <a onClick={()=>{
                                                let _tmr = window._tmr || (window._tmr = []);
                                                _tmr.push({"type":"reachGoal","id":3245002,"goal":"clickSocial"});
                                            }} href="https://youtube.com/channel/UCmkej_XHREE9qLWNxuSZfhQ" target="_blank">
                                                <i className="icon__yt-gr"></i>
                                            </a>
                                            <a onClick={()=>{
                                                let _tmr = window._tmr || (window._tmr = []);
                                                _tmr.push({"type":"reachGoal","id":3245002,"goal":"clickSocial"});
                                            }} href="https://t.me/kd_turgenev" target="_blank">
                                                <i className="icon__tg-gr"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 col__custom">
                                    <div className="nav__menu">
                                        <ul>
                                            <li>
                                                <Link onClick={()=>{
                                                    this.closeNav();
                                                    let _tmr = window._tmr || (window._tmr = []);
                                                    _tmr.push({"type":"reachGoal","id":3245002,"goal":"clickProject"});
                                                }}  to='/about' className="gr-item"><I18n t="nav.item1"/></Link>
                                            </li>
                                            <li>
                                                <Link onClick={()=>{
                                                    this.closeNav();
                                                    let _tmr = window._tmr || (window._tmr = []);
                                                    _tmr.push({"type":"reachGoal","id":3245002,"goal":"clickInfrastucture"});
                                                }} to='/infrastructure' className="gr-item"><I18n t="nav.item3"/></Link>
                                            </li>
                                            <li>
                                                <Link onClick={()=>{
                                                    this.closeNav();
                                                    let _tmr = window._tmr || (window._tmr = []);
                                                    _tmr.push({"type":"reachGoal","id":3245002,"goal":"clickFlats"});
                                                }} to='/apartments' className="gr-item"><I18n t="nav.item4"/></Link>
                                            </li>
                                            <li>
                                                <Link onClick={()=>{
                                                    this.closeNav();
                                                    let _tmr = window._tmr || (window._tmr = []);
                                                    _tmr.push({"type":"reachGoal","id":3245002,"goal":"clickPenth"});
                                                }} to='/penthouse' className="gr-item"><I18n t="nav.item5"/></Link>
                                            </li>
                                            <li>
                                                <Link onClick={()=>{
                                                    this.closeNav();
                                                    let _tmr = window._tmr || (window._tmr = []);
                                                    _tmr.push({"type":"reachGoal","id":3245002,"goal":"clickPurchase"});
                                                }} to='/buy' className="gr-item"><I18n t="nav.item6"/></Link>
                                            </li>
                                            <li>
                                                <Link onClick={()=>{
                                                    this.closeNav();
                                                    let _tmr = window._tmr || (window._tmr = []);
                                                    _tmr.push({"type":"reachGoal","id":3245002,"goal":"clickPartners"});
                                                }} to='/partners' className="gr-item"><I18n t="nav.item7"/></Link>
                                            </li>
                                            <li>
                                                <Link onClick={()=>{
                                                    this.closeNav();
                                                    let _tmr = window._tmr || (window._tmr = []);
                                                    _tmr.push({"type":"reachGoal","id":3245002,"goal":"clickBuild"});
                                                }} to='/progress' className="gr-item"><I18n t="nav.item8"/></Link>
                                            </li>
                                            <li>
                                                <Link onClick={()=>{
                                                    this.closeNav();
                                                    let _tmr = window._tmr || (window._tmr = []);
                                                    _tmr.push({"type":"reachGoal","id":3245002,"goal":"clickNews"});
                                                }}  to='/news' className="gr-item"><I18n t="nav.item2"/></Link>
                                            </li>
                                            <li>
                                                <Link onClick={()=>{
                                                    this.closeNav();
                                                    let _tmr = window._tmr || (window._tmr = []);
                                                    _tmr.push({"type":"reachGoal","id":3245002,"goal":"clickSmi"});
                                                }}  to='/mass-media' className="gr-item"><I18n t="nav.item11"/></Link>
                                            </li>
                                            <li>
                                                <Link onClick={()=>{
                                                    this.closeNav();

                                                    let _tmr = window._tmr || (window._tmr = []);
                                                    _tmr.push({"type":"reachGoal","id":3245002,"goal":"clickBrokers"});
                                                }} to='/brokers' className="gr-item"><I18n t="nav.item9"/></Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )

    }
}
