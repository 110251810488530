import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Home from './Home'
import Card from './Card'

const base = '/:locale(en)?';

const Apartment = () => (
    <Switch>
        <Route exact path={base} component={Home}/>
        <Route path={`${base}/apartment/:direction`} component={Card}/>
    </Switch>
)
export default Apartment
