import React from 'react'
import "../assets/fonts/stylesheet.css"
import "../assets/css/bootstrap.min.css"
import "../assets/css/animate.css"
import "../assets/css/slick.min.css"
import "../assets/css/style.css"
import { Switch, Route } from 'react-router-dom'
import webp from "../utils";
import Apartment from "./Apartament";
import SmoothScroll from 'smoothscroll-for-websites'

let Home = React.lazy(() => import('./Home'));
let Partners = React.lazy(() => import('./Partners'));
let Contacts = React.lazy(() => import('./Contacts'));
let Infrastructure = React.lazy(() => import('./Infrastructure'));
let Apartments = React.lazy(() => import('./Apartments'));
let SelectParams = React.lazy(() => import('./SelectParams'));
let Brokers = React.lazy(() => import('./Brokers'));
let SelectFacade = React.lazy(() => import('./SelectFacade'));

let Buy = React.lazy(() => import('./Buy'));
let Progress = React.lazy(() => import('./Progress'));
let Inside = React.lazy(() => import('./Inside'));
let Outside = React.lazy(() => import('./Outside'));
let About = React.lazy(() => import('./About'));
let Penthouse = React.lazy(() => import('./Penthouse'));
let Penthouse2 = React.lazy(() => import('./Penthouse_old'));
let Profile = React.lazy(() => import('./Cabinet/Profile'));
let Deals = React.lazy(() => import('./Cabinet/Deals'));
let Special = React.lazy(() => import('./Cabinet/Special'));
let News = React.lazy(() => import('./News'));
let MassMedia = React.lazy(() => import('./MassMedia'));
let Post = React.lazy(() => import('./Post'));

require('es6-promise').polyfill();
require('isomorphic-fetch');

const base = '/:locale(en)?';

SmoothScroll({
    frameRate: 150,
    animationTime: 1000,
    stepSize: 100,
    pulseAlgorithm: 1,
    pulseScale: 4,
    pulseNormalize: 1,
    accelerationDelta: 50,
    accelerationMax: 3,
    keyboardSupport: 1,
    arrowScroll: 50,
    fixedBackground: 0
});

const loading = (
    <div></div>
)

export default class Main extends React.Component {
    componentDidMount() {
        if(!webp){
            this.setState({
                webp: false
            }, ()=>{
                document.body.classList.add('no-webp-mode');
            });
        }else{
            this.setState({
                webp: true
            }, ()=>{
                document.body.classList.add('webp-mode');
            });
        }
    }

    render(){
        return(
            <div id="page" className="page">
                <React.Suspense fallback={loading}>
                    <Switch>
                        <Route exact path={base} component={Home}/>
                        <Route path={`${base}/partners`} component={Partners}/>
                        <Route path={`${base}/contacts`} component={Contacts}/>
                        <Route path={`${base}/apartment`} component={Apartment}/>
                        <Route path={`${base}/infrastructure`} component={Infrastructure}/>
                        <Route path={`${base}/inside`} component={Inside}/>
                        <Route path={`${base}/outside`} component={Outside}/>
                        <Route path={`${base}/brokers`} component={Brokers}/>
                        <Route path={`${base}/buy`} component={Buy}/>
                        <Route path={`${base}/penthouse`} component={Penthouse}/>
                        <Route path={`${base}/penthouse-test`} component={Penthouse2}/>
                        <Route path={`${base}/apartments`} component={Apartments}/>
                        <Route path={`${base}/about`} component={About}/>
                        <Route path={`${base}/about/architecture`} component={About}/>
                        <Route path={`${base}/progress`} component={Progress}/>
                        <Route key="params" path={`${base}/select/params`} component={SelectParams}/>
                        <Route key="facade" path={`${base}/select/facade`} component={SelectFacade}/>
                        <Route path={`${base}/cabinet/profile`} component={Profile}/>
                        <Route path={`${base}/cabinet/deals`} component={Deals}/>
                        <Route path={`${base}/cabinet/special`} component={Special}/>
                        <Route exact path={`${base}/news/:direction`} component={Post}/>
                        <Route path={`${base}/news`} component={News}/>
                        <Route path={`${base}/mass-media/:direction`} component={Post}/>
                        <Route exact path={`${base}/mass-media`} component={MassMedia}/>

                    </Switch>
                </React.Suspense>
            </div>

        )
    }
}
